<script setup>

</script>

<template>
  <section class="bg-gray-50" id="contact">
    <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
      <div class="bg-white rounded-lg shadow-sm m-4 px-4 py-12 text-center">
        <div class="text-center max-w-screen-md mb-8 lg:mb-16 mx-auto">
          <h2 class="section-title text-main-light">Porozmawiajmy</h2>
          <h2 class="section-header text-gray-900 mt-0 mb-12">Dane kontaktowe</h2>
          <p class="section-description">
            Masz pomysł na projekt? Chętnie odpowiemy na wszystkie pytania!
          </p>
        </div>
        <div class="grid sm:grid-cols-3 gap-4">
          <div class="p-8">
            <div class="flex justify-center items-center mb-6 w-10 h-10 mx-auto">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-main">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
              </svg>
            </div>
            <a class="block text-gray-900 text-xl font-bold hover-scale hover-color" href="tel:+48782120445">
              782 120 445
            </a>
          </div>
          <div class="p-8">
            <div class="flex justify-center items-center mb-6 w-10 h-10 mx-auto">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-main">
                <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
              </svg>
            </div>
            <a class="block text-gray-900 text-xl font-bold hover-scale hover-color" href="mailto:kontakt@atpsolutions.pl">
              kontakt@atpsolutions.pl
            </a>
          </div>
          <div class="p-8">
            <div class="flex justify-center items-center mb-6 w-10 h-10 mx-auto">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-main">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
              </svg>
            </div>
            <a class="block text-gray-900 text-xl font-bold">
              Łańcut/Rzeszów
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<style scoped>

</style>
