<script setup>

</script>

<template>
  <section class="px-8 mt-24 md:mt-48 mb-8 antialiased" id="main">
    <div class="mx-auto grid max-w-screen-xl px-4 pb-8 md:grid-cols-12 lg:gap-12 xl:gap-0">
      <div class="justify-self-start md:col-span-7 md:text-start text-center md:text-left">
        <h1 class="mb-4 hero-header">Tworzymy strony, które rozwiną Twój biznes</h1>
        <p class="my-8 hero-subheader">Projektujemy i wdrażamy nowoczesne strony i aplikacje internetowe. Budujemy funkcjonalne e-sklepy, zapewniając kompleksowe wsparcie w zakresie hostingu oraz zarządzania serwisami online. Wykorzystujemy najnowsze technologie, w tym sztuczną inteligencję, aby zwiększyć efektywność i innowacyjność naszych rozwiązań.</p>
        <div class="mt-16 mb-4">
          <a class="btn btn-main btn-xl" href="#contact">Skontaktuj się z nami!</a>
        </div>
      </div>
      <div class="hidden md:col-span-5 md:mt-0 md:flex px-16">
        <img src="./../../assets/images/digital.jpg" class="rounded-xl object-contain">
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>
