<template>
 <Navbar/>
  <Home/>
  <Footer/>
</template>

<script>
import { onMounted } from "vue";
import { initFlowbite} from "flowbite";
import Navbar from "@/components/layout/Navbar.vue";
import Footer from "@/components/layout/Footer.vue";
import Home from "@/components/pages/Home.vue";

onMounted(() => {
  initFlowbite();
})

export default {
  name: 'App',
  components: {
    Home,
    Footer,
    Navbar
  }
}
</script>

<style>

</style>
