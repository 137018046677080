<script >
  export default {
    data() {
      return {
        images: [
            'angular.png',
            'vue.png',
            'docker.png',
            'elasticsearch.png',
            'kubernetes.png',
            'mysql.png',
            'php.png',
            'python.png',
            'symfony.png',
            'ts.png'
        ]
      };
    }
  };
</script>

<template>
  <section class="bg-gray-50" id="technologies">
    <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
      <div class="text-center max-w-screen-md mb-8 lg:mb-16 mx-auto">
        <h2 class="section-title text-main-light">Narzędzia</h2>
        <h2 class="section-header text-gray-900 mt-0 mb-12">Wykorzystywane technologie</h2>
        <p class="section-description">
          W naszej pracy korzystamy z najnowszych i najbardziej sprawdzonych technologii, m.in.:
        </p>
      </div>
      <div class="logo-wrapper flex gap-4 flex-wrap justify-center">

        <div class="logo-item" v-for="(image, index) in images" :key="index">
          <div class="flex justify-center items-center rounded-lg p-2 mx-auto bg-white rounded-lg shadow-sm">
            <img class="logo-image"
                 :src="require(`@/assets/images/technologies/${image}`)"/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.logo-wrapper {
  .logo-item {
    width: 200px;
    height: 200px;
    >div {
      height: 100%;
      width: 100%;
      .logo-image {
        max-width: 140px;
        max-height: 100px;
      }
    }

  }
  @media screen and (max-width: 767px) {
    .logo-item {
      width: 140px;
      height: 140px;
      >div {
        .logo-image {
          max-width: 100px;
          max-height: 40px;
        }
      }
    }
  }
}


</style>
