<script setup>

</script>

<template>


  <nav class="navbar fixed w-full z-20 top-0 start-0" id="navbar">
    <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
      <a href="/" class="flex items-center space-x-3">
        <img src="./../../assets/images/atp-logo.png" class="h-8" alt="ATP Solutions Logo"/>
      </a>
      <div class="flex md:order-2 space-x-3 md:space-x-0">
        <a class="btn btn-main btn-sm hidden md:inline" href="#contact">Skontaktuj się z nami</a>
        <button data-collapse-toggle="navbar-sticky" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="navbar-sticky" aria-expanded="false">
          <span class="sr-only">Open main menu</span>
          <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
          </svg>
        </button>
      </div>
      <div class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">
        <ul class="flex flex-col p-4 md:p-0 mt-4 font-medium rounded-lg bg-transparent md:space-x-8 md:flex-row md:mt-0">
          <li>
            <a href="#features" class="block py-2 px-3 font-semibold text-white rounded md:bg-transparent md:p-0" aria-current="page">Usługi</a>
          </li>
          <li>
            <a href="#about" class="block py-2 px-3 font-semibold text-white rounded md:bg-transparent md:p-0" aria-current="page">O nas</a>
          </li>
          <li>
            <a href="#contact" class="block py-2 px-3 font-semibold text-white rounded md:bg-transparent md:p-0" aria-current="page">Kontakt</a>
          </li>
          <li class="my-4 md:hidden">
            <a class="btn btn-main btn-sm block text-center" href="#contact">Skontaktuj się z nami</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
@use './../../styles/variables';

nav.navbar {
  background-color: variables.$bg-black;
}
</style>
