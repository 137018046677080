<script setup>

</script>

<template>
  <section class="bg-dark" id="features">
    <div class="py-8 px-4 mx-auto max-w-screen-xl sm:pt-24 lg:px-6">
      <div class="text-center max-w-screen-md mb-8 lg:mb-16 mx-auto">
        <h2 class="section-title text-main-light">Usługi</h2>
        <h2 class="section-header text-white mt-0 mb-12">Jak możemy pomóc?</h2>
        <p class="section-description">
          Zrealizujemy kompleksowy proces, obejmujący projektowanie UX dla stron internetowych i sklepów online, aż po ich wdrożenie w sieci. Zapewniamy pełen zakres usług programistycznych, dostosowanych do indywidualnych potrzeb Twojego biznesu. Nasz zespół składa się z doświadczonych specjalistów, którzy pomogą Ci w każdym etapie realizacji projektu, od pomysłu po finalne wdrożenie.
        </p>
      </div>
      <div class="space-y-8 px-4 text-center md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
        <div>
          <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 mx-auto">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-main-lighten">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25" />
            </svg>
          </div>
          <h3 class="mb-4 text-2xl font-bold text-white">Aplikacje webowe</h3>
          <p class="text-base text-light">Tworzymy nowoczesne aplikacje internetowe, które są idealnie dostosowane do Twoich potrzeb, łącząc innowacyjność z funkcjonalnością. Nasze rozwiązania projektujemy z myślą o użytkownikach, zapewniając intuicyjny interfejs oraz wysoką wydajność. Dzięki zastosowaniu najnowszych technologii, nasze aplikacje są nie tylko estetyczne, ale również bezpieczne i responsywne.</p>
        </div>
        <div>
          <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 mx-auto">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-main-lighten">
              <path stroke-linecap="round" stroke-linejoin="round" d="M14.25 9.75 16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
            </svg>
          </div>
          <h3 class="mb-4 text-2xl font-bold text-white">Strony internetowe</h3>
          <p class="text-base text-light">
            Od ponad 10 lat tworzymy strony internetowe. Nasze doświadczenie pozwala nam na projektowanie rozwiązań, które nie tylko przyciągają uwagę, ale także skutecznie wspierają cele biznesowe. Dzięki zastosowaniu nowoczesnych technologii i najlepszych praktyk branżowych, każda strona, którą tworzymy, jest responsywna, szybka i łatwa w obsłudze.
          </p>
        </div>
        <div>
          <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 mx-auto">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-main-lighten">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
            </svg>
          </div>
          <h3 class="mb-4 text-2xl font-bold text-white">Sklepy internetowe</h3>
          <p class="text-base text-light">
            Tworzymy sklepy internetowe na platformach WordPress i Magento, które są dostosowane do potrzeb Twojego biznesu, umożliwiając zaawansowane zarządzanie produktami i zamówieniami. Dzięki naszym rozwiązaniom zyskujesz elastyczność, wydajność oraz możliwość rozwoju swojego e-sklepu.
          </p>
        </div>
        <div>
          <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 mx-auto">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-main-lighten">
              <path stroke-linecap="round" stroke-linejoin="round" d="m6.75 7.5 3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0 0 21 18V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v12a2.25 2.25 0 0 0 2.25 2.25Z" />
            </svg>
          </div>
          <h3 class="mb-4 text-2xl font-bold text-white">Utrzymanie</h3>
          <p class="text-base text-light">
            Zajmujemy się samodzielnym tworzeniem infrastruktury IT oraz zarządzaniem serwerami i bazami danych. Proponujemy również wsparcie techniczne oraz utrzymanie aplikacji. Zapewniamy opiekę nad serwisami, zapewniając im stabilność i bezpieczeństwo.
          </p>
        </div>
        <div>
          <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 mx-auto">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-main-lighten">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5" />
            </svg>
          </div>
          <h3 class="mb-4 text-2xl font-bold text-white">Pobieranie danych</h3>
          <p class="text-base text-light">
            Zajmujemy się tworzeniem zaawansowanych parserów, które umożliwiają efektywne pobieranie danych ze stron internetowych. Nasze rozwiązania pozwalają na zbieranie różnorodnych informacji, od cenników po dane statystyczne, umożliwiając stałe monitorowanie danych i reagowanie na zmiany.
          </p>
        </div>
        <div>
          <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 mx-auto">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-main-lighten">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z" />
            </svg>
          </div>
          <h3 class="mb-4 text-2xl font-bold text-white">Hosting Wordpress</h3>
          <p class="text-base text-light">
            Oferujemy profesjonalny hosting WordPress, który zapewnia optymalną wydajność i bezpieczeństwo Twojej strony. Nasze serwery korzystają z dysków SSD NVMe, co gwarantuje szybkie ładowanie witryn oraz wysoką dostępność.
          </p>
        </div>
      </div>
      <div class="mx-8 mt-20 sm:mt-32 mb-16 text-center">
        <a class="btn btn-main btn-xl" href="#contact">Skontaktuj się z nami</a>
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>
